// Points of interests, the "table", ".empty-table" & "table-wrapper"
table {
  width: 100%;
  margin: 0 auto;
  box-shadow: none !important;

  tr.mat-header-row,
  mat-header-row {
    background-color: #ededef;
  }

  tr.mat-row:hover,
  mat-row:hover {
    background-color: #f4f4f5;
    cursor: pointer;
  }

  tr.mat-row:active,
  mat-row:active {
    background-color: #d9f1fe;
  }

  tr.mat-row {
    &.alarm {
      background-color: rgb(255, 59, 48, 0.12);
    }

    &.warning {
      background-color: rgba(255, 149, 0, 0.2);
    }

    &.notification {
      background-color: rgba(255, 204, 0, 0.1);
    }

  }

  td.mat-mdc-cell {
    &.alarm {
      background-color: rgba(255, 59, 48, 0.12);
    }

    // The not is to prevent a cell from having same class as parent row.
    &.warning:not(.warning .mat-mdc-cell) {
      background-color: rgba(255, 149, 0, 0.2);
    }

    &.notification {
      background-color: rgba(255, 204, 0, 0.1);
    }
  }

  mat-cell,
  mat-header-cell {
    &.narrow {
      flex: 0.5;
    }
  }

  &.small {

    tr.mat-row,
    tr.mat-header-row {
      height: 36px;
    }

    mat-row,
    mat-header-row {
      min-height: 36px;
    }
  }

  &.medium {

    tr.mat-row,
    tr.mat-header-row {
      height: 44px;
    }

    mat-row,
    mat-header-row {
      min-height: 44px;
    }
  }

  &.large {

    tr.mat-row,
    tr.mat-header-row {
      height: 52px;
    }

    mat-row,
    mat-header-row {
      min-height: 52px;
    }
  }

  th.mat-header-cell,
  mat-header-cell {
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
  }

  .no-results,
  .no-readings {
    padding: 8px 16px !important;
    white-space: nowrap;
  }

  .actions-col-right {
    justify-content: flex-end;
    flex: unset;
  }

  .column-small {
    width: 25px;
  }

  .flex-2 {
    flex: 2;
  }

  .flex-3 {
    flex: 3;
  }
}

.project-measurement-table-wrapper {
  position: relative;
  flex: 1;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  overflow: auto;
  min-height: 400px;

  table {
    width: 100%;
    margin: 0 auto;
    box-shadow: none !important;

    th.mat-mdc-header-cell {
      height: 40px;
      color: RGBA(255, 255, 255, 0.87);
      font-size: 14px;
      padding: 0 16px !important;
    }

    tr.mat-mdc-header-row {
      background-color: RGB(39, 57, 67);
    }

    tr.mat-mdc-row:hover {
      background-color: RGBA(244, 244, 245);
      cursor: pointer;
    }

    tr.mat-mdc-row:active {
      background-color: RGBA(0, 157, 247, 0.12);
    }

    td.mat-mdc-cell {
      padding: 0 16px !important;

      &.light-text {
        color: RGBA(0, 0, 0, 0.38);
      }
    }
  }
}

.alarms-warning-table-wrapper {
  flex: 1;
  width: 100%;
  min-height: 400px;
  background-color: white;
  position: relative;
  overflow: auto;

  table {
    width: 100%;
    margin: 0 auto;
    box-shadow: none !important;

    tr.mat-header-row {
      background-color: #ededef;
    }

    tr.mat-row {
      &.alarm {
        background-color: rgb(255, 59, 48, 0.12);
      }

      &.notification {
        background-color: rgb(255, 255, 255);
      }

      &.warning {
        background-color: rgb(255, 204, 0, 0.12);
      }
    }

    tr.mat-row:hover {
      background-color: #f4f4f5;
      cursor: pointer;
    }

    tr.mat-row:active {
      background-color: #d9f1fe;
    }

    &.small {

      tr.mat-row,
      tr.mat-header-row {
        height: 36px;
      }
    }

    &.medium {

      tr.mat-row,
      tr.mat-header-row {
        height: 44px;
      }
    }

    &.large {

      tr.mat-row,
      tr.mat-header-row {
        height: 52px;
      }
    }

    th.mat-header-cell {
      color: rgba(0, 0, 0, 0.87);
      font-size: 14px;
    }

    .actions-col-right {
      justify-content: flex-end;
    }

    .column-small {
      width: 25px;
    }
  }
}

@mixin action-icons($color: none) {
  mat-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: unset;
    color: $color;
  }
}

.location-measurements-table-wrapper,
.project-measurements-table-wrapper,
.project-table-wrapper,
.initiative-table-wrapper,
.location-table-wrapper,
.notification-per-measurement-table-wrapper,
.public-api-connections-table-wrapper,
.alarms-warning-table-wrapper {
  position: relative;
  flex: 1;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  overflow: auto;
  max-height: 600px;

  table {
    width: 100%;
    margin: 0 auto;
    box-shadow: none !important;

    th.mat-mdc-header-cell {
      height: 40px;
      color: RGBA(255, 255, 255, 0.87);
      font-size: 14px;
      padding: 0 16px !important;

      .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
        border-color: white;
      }
    }

    tr.mat-mdc-header-row {
      background-color: RGB(39, 57, 67);
    }

    tr.mat-mdc-row {
      &.error_outline {
        background-color: RGBA(255, 204, 0, 0.1);
        @include action-icons($color: #009df7);
      }

      &.notification {
        background-color: RGBA(255, 204, 0, 0.1);
        @include action-icons($color: #009df7);
      }

      &.warning {
        background-color: RGBA(255, 149, 0, 0.12);
        @include action-icons($color: #ff9500);
      }

      &.alarm {
        background-color: rgba(255, 59, 48, 0.12);
        @include action-icons($color: #ff3b30);
      }

      &.error {
        background-color: RGBA(255, 59, 48, 0.12);
        @include action-icons($color: #ff3b30);
      }

      &.disable {
        background-color: rgba(0, 0, 0, 0.15);

        &:hover {
          background-color: rgba(0, 0, 0, 0.15) !important;
          cursor: default;
        }
      }
    }

    tr.mat-mdc-row:hover {
      background-color: RGBA(244, 244, 245);
      cursor: pointer;
    }

    tr.mat-mdc-row:active {
      background-color: RGBA(0, 157, 247, 0.12);
    }

    td.mat-mdc-cell {
      padding: 0 16px !important;

      &.light-text {
        color: RGBA(0, 0, 0, 0.38);
      }
    }

    .action {
      width: 100px;
    }
  }
}

.blastings-table-wrapper {
  table {
    th {
      background-color: hsla(201, 26%, 21%, 1) !important;
      color: #ffffff !important;

      mat-checkbox {
        .mdc-checkbox__background {
          border-color: white !important;
        }

        color: white !important;
      }
    }
  }
}