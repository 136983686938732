@import "./app/devices/devices.shared.scss";
@import "./styles/rb.material.custom.scss";
@import "./r2-material-custom-styling.scss";
@import "./styles/shared/table.scss";
@import "leaflet/dist/leaflet.css";

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
  font-family: Roboto;
  font-size: 14px;
}

body {
  background-color: #fafafa;
  margin: 0;
  padding: 0;
}

a {
  color: rgb(0, 157, 247);
  text-decoration: none;
}

h2,
h4,
h5 {
  font-weight: 500;
}

.mat-typography h3 {
  font-weight: 500;
}

.mat-list-item-content {
  column-gap: 8px;
}

.top-margin {
  margin-top: 16px;
}

.mat-dialog-container {
  padding: 0 !important;
}

.ng-image-slider .ng-image-slider-container .main {
  .main-inner {
    display: flex;
    justify-content: center;
    width: 100% !important;
    align-items: center;
  }
}

.image-actions {
  .mat-button-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
}

.img-div {
  width: 64px !important;
  height: 48px !important;

  .image {
    object-fit: contain !important;
    max-width: 100% !important;
  }

  opacity: 0.2;
  cursor: pointer !important;
}

.mat-horizontal-stepper-header {
  margin: 8px !important;
  padding: 0 8px !important;
}

.mat-step-header {
  height: 32px !important;
  margin: 8px !important;
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

.mat-form-field-no-padding .mat-form-field-wrapper {
  margin-bottom: -1.25em;
  padding: 0px;
}

/* Snack-bars */
simple-snack-bar.mat-simple-snackbar {
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  font-family: "Roboto";
  font-weight: normal;
  letter-spacing: 0.25px;
  line-height: 20px;
}

.snack-bar-success {
  background: #34c759;
}

.snack-bar-success-dark {
  background: rgb(39, 57, 67);
}

.snack-bar-failure {
  background: red;
}

/* Styles form material stuff */
mat-sidenav-container.mat-drawer-container {
  height: 100%;
}

mat-sidenav-content.mat-drawer-content {
  display: flex;
  flex-direction: column;
}

/* Material styling for new device design */
.device {

  /* Mat dialog */
  .mat-dialog-container {
    border: solid 1px rgb(230, 230, 230);
  }

  /* Mat button */
  .mat-button-focus-overlay {
    background-color: transparent;
  }

  /* Mat form field */
  .mat-form-field {
    line-height: 1.65;

    // remove margin in mat form field
    .mat-form-field-wrapper {
      margin-bottom: 5px;

      .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 0;
      }
    }

    &.invisible {
      line-height: 20px;
      margin-right: -10px;
      margin-left: 10px;

      &.small {
        .mat-form-field-flex {
          width: 150px;
        }
      }

      .mat-form-field-wrapper {
        padding-bottom: 0;

        .mat-form-field-infix {
          padding: 2px 0 9px;

          &:has(.mat-datepicker-input) {
            width: 90px;
          }

          .mat-form-field-label-wrapper {
            top: -20px;
            padding-top: 20px;
          }

          .mat-select-trigger {
            align-items: flex-end;

            .mat-select-value {
              display: flex;
              justify-content: flex-end;
              padding-right: 10px;
            }
          }
        }

        .mat-form-field-suffix .mat-datepicker-toggle {
          button {
            width: unset;

            .mat-button-wrapper {
              margin-right: 0;
              padding-right: 0;
            }
          }
        }
      }

      .mat-form-field-outline {
        background-color: white;

        div {
          border: none;
        }
      }

      .mat-form-field-suffix {
        top: 8px;
      }

      .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        transform: translateY(-1.2em) scale(0.75);
        width: 133.3333333333%;
      }

      mat-icon {
        color: #009df7;
      }
    }

    &.low {
      line-height: 1.125;
    }
  }

  /* Mat radio group and containing mat radio button */
  .mat-radio-group {
    display: flex;
    margin-bottom: 24px;

    .mat-radio-button {
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.1);
      height: 32px;
      margin-right: 10px;
      padding: 0 12px;
      border-radius: 16px;

      &.active {
        background-color: rgba(0, 0, 0, 0.15);
      }

      .mat-radio-label-content {
        display: flex;
        padding: 0;
        color: rgba(0, 0, 0, 1);
        font-family: "Roboto", "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0, 25px;
        text-align: left;
        line-height: 20px;

        mat-icon {
          height: 19px;
          width: 19px;
          font-size: 19px;
          padding-right: 5px;
          color: black;
        }
      }

      .mat-radio-container {
        display: none;
      }
    }
  }

  /* Mat stepper */
  .mat-horizontal-stepper-header-container {
    padding: 24px 64px;
  }

  .mat-step-header {
    pointer-events: none;
  }

  .mat-step-header .mat-step-icon {
    background-color: rgba(0, 0, 0, 0.38);

    &.mat-step-icon-state-error {
      background-color: transparent;
    }
  }

  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: #05326e;
  }

  /* Mat accordion and containing expansion panel */
  .mat-accordion {
    width: 100%;

    .mat-expansion-panel {
      margin-bottom: 16px;
      border-radius: 4px;
      box-shadow: none;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:not(.mat-expanded),
      &:not(.mat-expansion-panel-spacing),
      &:last-of-type,
      &:first-of-type {
        border-radius: 4px;
      }

      &.green-border {
        border: 1px solid rgb(52, 199, 89);
      }

      &.orange-border {
        border: 1px solid rgb(255, 149, 0);
      }

      &.blue-border {
        border: 1px solid rgb(0, 157, 247);
      }

      .mat-expansion-panel-header {
        padding: 0 16px;

        &.mat-expanded {
          background-color: rgba(0, 157, 247, 0.08);
        }
      }
    }
  }

  /* Progress spinner */
  button {

    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: white;
    }
  }
}

.styled {
  mat-form-field.mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 15px;
    }

    &.pb-5 .mat-form-field-wrapper {
      padding-bottom: 5px;
    }

    .mat-form-field-infix {
      padding: 5px 0 9px;

      .mat-form-field-empty {
        top: 17px;
      }
    }

    .mat-form-field-outline {
      background-color: white;
    }

    .mat-form-field-suffix {
      top: 5px;
    }
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.6em) scale(0.75);

    &.mat-form-field-empty {
      transform: translateY(-1em) scale(0.75);
    }
  }

  div.mat-tab-body-wrapper {
    height: 100%;
  }

  mat-tab-header.mat-tab-header {
    .mat-tab-label-active .mat-tab-label-content {
      color: rgb(0, 157, 247);
    }
  }

  button.green-hover.mat-mdc-outlined-button {
    .mat-button-wrapper {
      position: absolute;
      top: -3px;
      left: 6px;
      z-index: 1;
    }

    .mat-mdc-button-persistent-ripple {
      &::before {
        background-color: rgb(52, 199, 89);
      }
    }

    &:hover {
      .mat-mdc-button-persistent-ripple {
        &::before {
          opacity: 1;
        }
      }

      &:not(:disabled) {
        color: #fff;
      }
    }

    .mat-button-focus-overlay {
      background-color: rgb(52, 199, 89);
    }
  }

  button.green-hover.mat-stroked-button:hover:not(.mat-button-disabled) {
    color: white;

    .mat-button-focus-overlay {
      opacity: 1;
    }
  }
}

.flex-spacer {
  flex: 1 1 auto;
}

/* Styles for filter/search bar */
#filters {
  mat-form-field.mat-form-field {
    margin-right: 10px;

    .mat-form-field-wrapper {
      padding-bottom: 10px;

      .mat-form-field-flex {
        background: rgb(237, 237, 239);
        border-radius: 16px;
        height: 32px;
        padding: 8px 0.75em 0 20px;

        .mat-form-field-infix {
          border-top: 0;

          .mat-form-field-label-wrapper {
            top: -13px;
            padding-top: 0.84375em;

            .mat-form-field-empty {
              top: 21px;
            }
          }

          .mat-select-arrow-wrapper {
            transform: translateY(-20%);
          }
        }
      }

      .mat-form-field-underline {
        display: none;
      }
    }
  }
}

/* Style did not fit for tasks filters, small adjustments*/
#filtersTasks {
  @extend #filters;

  mat-form-field.mat-form-field {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.25px;
    line-height: 20px;
    margin-top: 8px;

    .mat-form-field-wrapper {
      .mat-form-field-flex {
        background-color: #ededef !important;
        padding: 4px 0.75em 0 14px !important;

        .mat-form-field-infix {
          .mat-form-field-label-wrapper {
            top: -22px !important;

            .mat-form-field-empty {
              top: 26px !important;
            }
          }
        }
      }
    }
  }

  .measurement {
    padding: 0;
    margin: 0;

    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__trailing,
    .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__leading {
      border: none;
    }
  }
}

#search-field,
.companies,
.stepper-wrapper {
  .mat-form-field-wrapper {
    padding-bottom: 15px;
  }

  .mat-form-field-infix {
    padding: 2px 0 9px;

    .mat-form-field-label-wrapper {
      top: -20px;
      padding-top: 20px;
    }
  }

  .mat-form-field-outline {
    background-color: white;
  }

  .mat-form-field-suffix {
    top: 8px;
  }

  .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.2em) scale(0.75);
    width: 133.3333333333%;
  }

  mat-icon {
    color: #009df7;
  }
}

#create-btn {
  align-self: flex-end;
  margin-bottom: auto;

  button {
    height: 33px;

    .mat-button-wrapper {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      letter-spacing: 1.25px;
    }

    mat-icon {
      width: 20px;
      height: 20px;
      color: white;
      font-size: 20px;
    }
  }
}

/*
End styles for filter/search bar 
*/

table.mat-table {

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 16px;
  }

  th.mat-header-cell:last-of-type,
  td.mat-cell:last-of-type,
  td.mat-footer-cell:last-of-type {
    padding-right: 16px;
  }

  mat-cell.mat-cell:first-of-type,
  mat-header-cell.mat-header-cell:first-of-type {
    padding-left: 5px;
  }

  mat-cell.mat-cell:last-of-type,
  mat-header-cell.mat-header-cell:last-of-type {
    padding-right: 10px;
  }

  // New tables
  td.mat-cell:first-of-type,
  th.mat-header-cell:first-of-type,
  th.mat-footer-cell:first-of-type {
    padding-left: 16px;
    padding-right: 13px;
  }

  td.mat-cell:last-of-type,
  th.mat-header-cell:last-of-type,
  th.mat-footer-cell:last-of-type {
    padding-left: 16px;
    padding-right: 6px;
  }
}

.more_menu {
  .mat-menu-content:not(:empty) {
    padding-top: 0 !important;

    button {
      border-bottom: 1px solid rgb(230, 230, 230) !important;
    }
  }
}

.user-tab-wrapper {
  .mat-tab-label {
    padding: 40px 40px 24px 40px;
    width: 50%;
    font-size: 16px;
  }
}

.device-network-details-wrapper {
  .mat-expansion-panel-body {
    padding: 0;
  }

  .device-information-list-item {
    .mat-select-arrow-wrapper {
      margin-left: 8px;
      padding: 0;
    }
  }
}

.media-manager {
  .mat-tab-label {
    color: rgb(216, 207, 228);
  }

  .mat-tab-label-active {
    color: #ffffff;
    opacity: 1;
  }

  mat-tab-header.mat-tab-header {
    background-color: rgba(39, 57, 67, 1);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .mat-tab-body-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mat-tab-list {
    flex-grow: 0;
  }

  .mat-tab-labels {
    letter-spacing: 1.25px;
    height: 60px;
    align-items: center;
  }
}

.expanded-section-device-transmissions {
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  // remove margin in mat form field
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.initiative-details-basic {
  .mat-form-field-infix {
    width: 100% !important;
  }
}

.comments {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-infix {
    min-height: unset;
  }

  .comment-send {
    button {
      .mdc-button__label {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: flex;
          align-items: center;
          justify-content: center;

          .mat-icon {
            height: 21px;
            width: 21px;
            font-size: 21px;
          }
        }
      }
    }
  }
}

.add-assets-dialog {
  .mat-form-field-wrapper {
    padding: 0;
    margin: 0 !important;
  }
}

app-user-data-tabs {
  .mat-mdc-tab-group {
    &.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header {
      .mat-mdc-tab {
        flex-grow: 1 !important;

        &.mdc-tab {
          height: 64px;
        }
      }
    }
  }
}

app-location-basic-info,
app-location-measurement-details {
  .list-item {
    .mat-mdc-form-field {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }
  }
}

app-dropdown-card {
  .mat-mdc-form-field {
    &:not(.last-child) {
      margin-bottom: 12px;
    }

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }
}

/* dark scrollbar for readings and values tables */
.dark-scrollbar {

  /* width */
  ::-webkit-scrollbar {
    border-radius: 2px;
    width: 14px;
    margin-right: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: RGBA(0, 0, 0, 0.6);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background-color: RGBA(0, 0, 0, 0.6);
  }
}

.common-dialog {
  .common-dialog-forms {
    mat-checkbox {
      label {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.74);
        line-height: 1.429;
      }
    }
  }
}

.overlay {
  .checkbox-container {
    .mdc-checkbox {
      position: relative;
      top: -8px;
    }

    .mat-mdc-checkbox {
      label {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.429;
        color: rgba(0, 0, 0, 0.74);
      }
    }
  }
}

ul.menu {
  background-color: #fff;
  border-radius: 4px;
  padding: 8px 0;
  margin: 0;
  overflow: hidden;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14);

  li {
    list-style: none;
    padding: 6px 16px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;

    &:hover {
      background-color: RGBA(0, 0, 0, 0.08);
    }
  }
}

.graph-list-item {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-chip {
    &.mat-mdc-standard-chip {
      &.mat-accent {
        &.mat-mdc-chip-selected {
          border: 1px solid rgb(0, 157, 247);
          background-color: rgba(0, 157, 247, 0.12);
        }
      }

      &.mdc-evolution-chip--with-primary-graphic {
        .mdc-evolution-chip__action--primary {
          padding: 6px 12px;
        }
      }
    }

    .mdc-evolution-chip__action {
      .mdc-evolution-chip__text-label {
        margin: 0;
        color: rgba(0, 0, 0, 0.87);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}

// Graph
.graph-tooltip {
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14);
  background-color: rgba(255, 255, 255, 1);
  min-width: 256px;

  .header {
    padding: 10px 16px 16px 16px;
    background-color: rgba(0, 157, 247, 0.06);

    .title {
      color: rgba(0, 0, 0, 0.6);
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      text-transform: uppercase;
    }

    .reading {
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      align-items: center;
    }

    .time {
      color: rgba(0, 0, 0, 0.6);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .content {
    padding-left: 16px;

    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6px 16px 6px 0;
      font-size: 14px;
      line-height: 1.429;
      font-weight: 400;
      gap: 16px;

      &:not(:last-of-type) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      }

      .title {
        color: rgba(0, 0, 0, 1);
      }

      .data {
        color: rgba(0, 0, 0, 0.74);
        display: flex;
        align-items: center;
        gap: 8px;

        mat-icon {
          font-size: 20px;
          height: 20px;
          width: 20px;
          cursor: pointer;

          &.link {
            width: 14px;
          }
        }

        &.error {
          color: rgb(255, 59, 48);
        }

        &.success {
          color: rgb(52, 199, 89);
        }
      }
    }
  }
}

.graph-selection {
  border: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 18px 16px 18px;

  apx-chart {
    div {
      min-height: unset !important;
    }
  }
}

.table-wrapper {
  position: relative;

  .selectbox-header {
    color: white;

    .mdc-checkbox__background {
      border-color: white !important;
    }
  }
}

.leaflet-popup-content-wrapper,
.leaflet-popup-tip {
  width: 380px;
  height: fit-content;
  max-height: 500px;
  overflow-y: auto;
}

.leaflet-popup-close-button {
  display: none;
}

.settlements-icon {
  border-radius: 50%;
  color: white;
  background-color: #009DF7;
  width: 24px !important;
  height: 24px !important;
  font-size: 14px;
  font-family: 'roboto';
  text-align: center;

  p {
    transform: translateY(3px) !important;
  }
}

.popup {
  display: block;
  width: 335px;
  padding: 14px 0;
  color: rgba(0, 0, 0, 1);
  font-family: "Roboto", "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.25px;
  line-height: 20px;

  .pb-8 {
    padding-bottom: 8px;
  }

  .pb-16 {
    padding-bottom: 16px;
  }

  .pt-16 {
    padding-top: 16px;
  }

  .pt-24 {
    padding-top: 24px;
  }

  .ml-8 {
    margin-left: 8px;
  }

  .font-12 {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }

  .font-20 {
    font-size: 20px;
    padding-left: 5px;
  }

  .no-border {
    border-bottom: none !important;
  }

  .header {
    color: #00000099;
    font-size: 10px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
  }

  .initiative-section-wrapper {
    width: 100%;
    border-bottom: 1px solid rgb(0, 0, 0, 0.15);

    &:last-of-type {
      border: none;
      padding-bottom: 8px;
    }

    .measurement-inactive-header {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    .last-reading {
      color: #00000099;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.4px;
    }


    .section[tooltip]:hover::after {
      content: attr(tooltip);
      background: RGBA(0, 0, 0, 0.6);
      color: RGBA(255, 255, 255, 0.87);
      padding: 4px;
      position: absolute;
      left: 20%;
      bottom: -60%;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0, 4px;
      line-height: 16px;
      z-index: 5000;
      border: none;
    }

    .default-cursor {
      cursor: text !important;
    }

    .overdue {
      .section-element {
        color: #FF3B30 !important;
      }
    }

    .section {
      display: flex;
      color: #000000;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      position: relative;
      border: none;

      &.align-items-top {
        align-items: flex-start;
      }

      .section-element-notification {
        display: flex;
        flex-direction: column;

        .section-element-notification-level {
          display: flex;
        }
      }

      .section-element {
        display: flex;
        align-items: center;
        color: RGBA(0, 0, 0, 0.74);
      }

      .alarm {
        color: RGB(255, 59, 48);
      }

      .warning {
        color: RGB(255, 149, 0);
      }

      .notification {
        color: RGB(0, 157, 247);
      }

      .completed {
        color: #34C759;
      }

      .status {
        color: #00000099;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.25px;
      }
    }

  }

  .section-wrapper {
    width: 100%;
    border: 1px solid rgb(0, 0, 0, 0.15);
    border-radius: 4px;
    margin-bottom: 16px;

    .measurement {
      &:not(:last-child) {
        border-bottom: 1px solid rgb(0, 0, 0, 0.15);
      }

      .measurement-element {
        display: flex;
        flex-direction: column;

        .isHandel {
          color: rgba(18, 18, 18, 0.74);
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0, 4px;
          line-height: 16px;
        }
      }
    }

    .section[tooltip]:hover::after {
      content: attr(tooltip);
      background: RGBA(0, 0, 0, 0.6);
      color: RGBA(255, 255, 255, 0.87);
      padding: 4px;
      position: absolute;
      left: 20%;
      bottom: -60%;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0, 4px;
      line-height: 16px;
      z-index: 5000;
    }

    .section {
      height: 12px;
      padding: 16px;
      display: flex;
      color: #000000;
      cursor: pointer;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &:focus,
      &:hover {
        background-color: RGBA(0, 157, 247, 0.08);
      }

      .material-icons {
        font-size: 16px;
        padding-left: 10px;
      }

      .section-element {
        display: flex;
        align-items: center;
        color: RGBA(0, 0, 0, 0.74);
      }

      .alarm {
        color: RGB(255, 59, 48);
      }

      .warning {
        color: RGB(255, 149, 0);
      }

      .notification {
        color: RGB(0, 157, 247);
      }
    }
  }
}

.popup-location {
  @extend .popup;
  width: 200px;
  height: 100px;
}

.empty-table {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%);
  text-align: center;

  p {
    margin-bottom: 20px;
  }
}

mat-form-field.textarea {
  label {
    top: 10px !important;
  }
}

#filtersTasks {
  display: flex;

  .selectedFilter {
    .mat-mdc-form-field-flex {
      background-color: RGBA(0, 157, 247, 0.12) !important;
      border: 1px solid #009DF7;
    }
  }
}

.search-add {
  display: flex;
  margin-bottom: -5px;

  #create-task {
    font-size: 14px;
    background: rgb(0, 157, 247);
    border-radius: 4px;
    height: 36px;
    font-weight: 400;
    letter-spacing: 1.25px;
    margin-left: 20px;
    margin-top: 2px;

    span {
      mat-icon {
        font-size: 22px;
        vertical-align: middle;
        margin-right: 4px;
      }
    }
  }

  .create-initiative-button {
    padding-top: 4px;
    padding-left: 10px;
  }
}

.ngx-mat-timepicker {
  font-size: 13px;
  padding: 16px;
  border: none;
}


// CSS for date time picker in blastings filter
.ngx-mat-timepicker form .ngx-mat-timepicker-table .ngx-mat-timepicker-tbody tr td .mat-form-field {
  max-width: none !important;
  width: 40px !important;

  &:hover {
    background-color: rgba(0, 157, 247, 0.3);
  }
}

.mat-datepicker-content .time-container:before {
  content: "Time" !important; // Todo: Somehow translate this
  background-color: transparent !important;
  height: 24px !important;
}

.mat-datepicker-content {
  text-align: center;

  .time-container {
    margin-top: 8px;
    border: none;

    td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.328);
    }
  }

  .actions {
    position: relative;
    top: -12px;

    button {
      background: rgb(0, 157, 247);
      color: #fff;
    }
  }
}

.context-menu-popup {
  .leaflet-popup-content-wrapper {
    width: 190px;
    min-height: 32px;
    overflow: hidden;
    border-radius: 0;
  }

  .leaflet-popup-content {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    min-height: 32px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;

    button {
      border: none;
      cursor: pointer;
      background-color: transparent;
    }

    div {
      button {
        padding: 6px 0 6px 6px;
      }
    }
  }
}

.location-tooltip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6) !important;
  border-color: rgba(0, 0, 0, 0.6) !important;

  button {
    color: white;
    border: none;
    cursor: pointer;
    background-color: transparent;
  }
}

// Assign user, user list overlay
.select-user-to-assign {
  flex-direction: column;
  overflow-y: auto;
  max-height: 60%;

}


//CSS For back button: all the tabs
.back {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-left: 50px;
  cursor: pointer;
  width: fit-content;

  span {
    color: rgb(0, 157, 247);
    font: 500 14px/16px "Roboto";
    letter-spacing: 1.25px;
    margin-left: 3px;
  }

  mat-icon {
    color: rgb(0, 157, 247);
  }
}

.tooltip-multiline-break {
  white-space: pre-line;
}

.workarea-users-menu {
  width: 400px !important;
  max-width: none !important;
}

// CSS ends
