.r2 {

  /* Mat button styling */
  .mat-flat-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    border-radius: 4px;
    height: 36px;
    padding: 0 24px;
    font-family: "Roboto", "Open Sans", sans-serif;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.25px;
    line-height: 16px;

    &:disabled {
      background-color: rgba(0, 157, 247, 0.5);
      color: #ffffff;
    }

    &.mat-danger {
      color: rgb(74, 74, 74);
    }
  }

  /* Mat form field styling */
  .mat-form-field {
    line-height: 1.125;

    .mat-form-field-wrapper {
      margin-bottom: 5px;
      padding-bottom: 8px;

      .mat-form-field-flex {
        width: 100%;
      }

      .mat-form-field-appearance-outline .mat-form-field-infix {
        padding: 0;
      }
    }

    .mat-form-field-infix {
      padding: 5px 0 9px;
      width: 100%;
      min-width: 0;

      .mat-form-field-empty {
        top: 17px;
      }
    }

    .mat-form-field-outline {
      background-color: white;
    }

    .mat-form-field-suffix {
      top: 0;
    }

    .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
      transform: translateY(-1.6em) scale(0.75);

      &.mat-form-field-empty {
        transform: translateY(-1em) scale(0.75);
      }
    }

    &.invisible {
      line-height: 20px;
      margin-right: -10px;
      margin-left: 10px;

      &.small {
        .mat-form-field-flex {
          width: 110px;
        }
      }

      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      .mat-form-field-infix {
        padding: 2px 0 9px;

        .mat-form-field-label-wrapper {
          top: -20px;
          padding-top: 20px;
        }
      }

      .mat-form-field-outline {
        background-color: white;

        div {
          border: none;
        }
      }

      .mat-form-field-suffix {
        top: 8px;
      }

      .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
        transform: translateY(-1.2em) scale(0.75);
        width: 133.3333333333%;
      }

      mat-icon {
        color: #009df7;
      }
    }

    &.low {
      line-height: 1.125;
    }
  }

  /* Mat horizontal stepper styling */
  .mat-stepper-horizontal {
    .mat-horizontal-stepper-header-container {
      padding: 24px 64px;

      .mat-step-header {
        pointer-events: none;

        .mat-step-icon {
          background-color: rgba(0, 0, 0, 0.38);

          &.mat-step-icon-state-error {
            background-color: transparent;
          }

          &.mat-step-icon-state-edit {
            background-color: #05326e;
          }
        }
      }
    }
  }

  /* Mat dialog container styling */
  .mat-dialog-container {
    border: solid 1px rgb(230, 230, 230);
  }

  /* Mat button styling */
  .mat-button-focus-overlay {
    background-color: transparent;
  }

  /* Mat radio group styling */
  .mat-radio-group {
    display: flex;
    margin-bottom: 24px;

    .mat-radio-button {
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.1);
      height: 32px;
      margin-right: 10px;
      padding: 0 12px;
      border-radius: 16px;

      &.active {
        background-color: rgba(0, 0, 0, 0.15);
      }

      .mat-radio-label-content {
        display: flex;
        padding: 0;
        color: rgba(0, 0, 0, 1);
        font-family: "Roboto", "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0, 25px;
        text-align: left;
        line-height: 20px;

        mat-icon {
          height: 19px;
          width: 19px;
          font-size: 19px;
          padding-right: 5px;
          color: black;
        }
      }

      .mat-radio-container {
        display: none;
      }
    }
  }

  .chips {
    &.mat-mdc-radio-group {
      display: flex;
      margin-bottom: 24px;

      .mdc-radio {
        display: none;
      }

      .mat-mdc-radio-button {
        display: flex;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.1);
        height: 32px;
        margin-right: 10px;
        padding: 0 12px;
        border-radius: 16px;

        &.active {
          background-color: rgba(0, 0, 0, 0.15);
        }

        .mat-icon {
          height: 14px;
          width: 19px;
          font-size: 19px;
          padding-right: 5px;
          color: black;
        }
      }
    }
  }

  /* Mat accordion and expansion panel styling */
  .mat-accordion {
    width: 100%;

    .mat-expansion-panel {
      margin-bottom: 16px;
      border-radius: 4px;
      box-shadow: none;

      &:last-of-type {
        margin-bottom: 0;
      }

      &:not(.mat-expanded),
      &:not(.mat-expansion-panel-spacing),
      &:last-of-type,
      &:first-of-type {
        border-radius: 4px;
      }

      &.green-border {
        border: 1px solid rgb(52, 199, 89);
      }

      &.orange-border {
        border: 1px solid rgb(255, 149, 0);
      }

      &.blue-border {
        border: 1px solid rgb(0, 157, 247);
      }

      // Style specific for measurements
      &.measurement {
        margin-bottom: 0;
        border-left: 1px solid #e6e6e6;
        border-right: 1px solid #e6e6e6;
        border-radius: 0;

        &:first-of-type {
          border-top: 1px solid #e6e6e6;
          border-radius: 4px 4px 0 0;
        }

        &:last-of-type {
          border-bottom: 1px solid #e6e6e6;
          border-radius: 0 0 4px 4px;
        }

        &.mat-expansion-panel-spacing {
          margin: 0;
        }
      }

      .mat-expansion-panel-header {
        padding: 0 16px;

        &.mat-expanded {
          background-color: rgba(0, 157, 247, 0.08);
        }

        .mat-expansion-panel-header-title {
          app-measurement-icon {
            mat-icon {
              color: rgb(5, 50, 110);
            }
          }
        }
      }
    }
  }

  &.sensors {
    .checkboxes {
      label {
        font-size: 12px !important;
        font-weight: 400 !important;
      }

      .mat-mdc-checkbox-checked .mdc-checkbox__background {
        background-color: #009df7 !important;
        border-color: #009df7 !important;
      }
    }
  }
}