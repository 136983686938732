@use "@angular/material" as mat;
@use "sass:map";
@import "./rb.material.palette.scss";

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
}

/* Material custom styling for add project by guide stepper dialog */
app-create-project-guide {
  .mat-mdc-radio-button label {
    font-weight: 400 !important;
  }
  .mat-step-header {
    cursor: none;
    pointer-events: none;
  }
}

#search-field {
  .mat-mdc-form-field-flex {
    background-color: white !important;
  }
}

#filters {
  mat-form-field.mat-mdc-form-field {
    margin-right: 10px;

    .mat-mdc-text-field-wrapper {
      background-color: transparent;
      padding: 0;

      .mat-mdc-form-field-focus-overlay {
        background-color: transparent;
      }

      .mdc-line-ripple {
        &::before {
          border: none;
        }
      }

      .mat-mdc-form-field-flex {
        background: rgb(237, 237, 239);
        border-radius: 16px;
        height: 34px;
        padding: 0px 0.75em 0 20px;

        .mat-mdc-form-field-infix {
          .mat-mdc-floating-label {
            top: 17px;
            transform: translateY(-50%);
            color: rgba(0, 0, 0, 0.6);
          }

          .mat-mdc-select-arrow-wrapper {
            transform: translateY(-4px);
          }
        }
      }
    }

    &:not(.mat-form-field-hide-placeholder) {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          .mdc-floating-label--float-above {
            transform: translateY(-6px) scale(0.6);
            top: unset;
          }
        }
      }
    }
  }
}

#filtersTasks {
  @extend #filters;

  .mat-mdc-form-field-flex {
    .mat-mdc-form-field-infix {
      .mat-mdc-select-trigger {
        .mat-mdc-select-value {
          transform: translateY(-4px);
        }
      }
    }

    .mdc-notched-outline__notch {
      border-top: 0;
      border-bottom: 0;
    }
  }

  .select-locations {
    .mat-mdc-form-field-flex {
      .mat-mdc-form-field-infix {
        .mat-mdc-select-trigger {
          .mat-mdc-select-value {
            transform: unset;
          }
        }
      }
    }
  }
}

#search-field {
  .mat-mdc-text-field-wrapper {
    background-color: #fff;

    .mat-mdc-form-field-infix {
      padding-top: 7px;
      padding-bottom: 5px;
      min-height: unset;
    }

    .mat-mdc-form-field-icon-suffix {
      .mat-icon {
        padding: 5px;
      }
    }
  }
}

.cdk-global-scrollblock {
  overflow-y: auto;
}

.mat-drawer {
  .mat-drawer-inner-container {
    h1 {
      font: 400 24px / 32px Roboto;
      letter-spacing: 0em;
      margin: 0 0 16px;
    }
  }

  .mat-mdc-list-item {
    .mdc-list-item__primary-text {
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      font-size: 16px;
      align-items: center;
      font-weight: 500;
    }

    &.active {
      .mdc-list-item__primary-text {
        color: inherit;
      }
    }
  }
}

.mat-mdc-unelevated-button {
  &.mat-primary {
    .mdc-button__label {
      color: #fff;
    }
  }
}

.mat-mdc-table {
  .mdc-data-table__header-cell {
    font-size: 14px;
    padding: 0;

    &:first-of-type {
      padding-left: 0;
      padding-right: 13px;
    }
  }

  .mdc-data-table__cell {
    padding: 0;
    font-size: 14px;
    font-weight: 400;

    &:first-of-type {
      padding-right: 13px;
    }

    .mat-mdc-icon-button {
      &.mat-mdc-button-base {
        padding: 0;
      }
    }
  }

  .mdc-data-table__row {
    height: 36px;

    &:hover {
      background-color: #f4f4f5 !important;
      cursor: pointer;
    }

    &:active {
      background-color: #d9f1fe;
    }
  }

  .mdc-data-table__header-row {
    height: 36px;
  }

  .mdc-checkbox {
    transform: scale(0.8);
  }

  td,
  th {
    word-wrap: none;
  }

  .mat-mdc-header-row {
    background-color: #ededef;
  }
}

.mat-mdc-paginator {

  .mat-mdc-paginator-page-size-label,
  .mat-mdc-paginator-range-label {
    color: rgba(0, 0, 0, 0.54);
  }

  .mat-mdc-paginator-page-size-select {
    width: 56px;
  }

  .mdc-text-field--outlined {
    padding: 0;

    .mdc-notched-outline {

      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border: none;
        border-bottom: 1px solid;
        border-radius: 0;
      }
    }
  }

  .mat-mdc-form-field-infix {
    min-height: 32px;
  }

  .mat-mdc-text-field-wrapper {
    &.mdc-text-field--outlined {
      .mat-mdc-form-field-infix {
        padding-bottom: 0px;
      }
    }
  }

  .mat-mdc-form-field {
    .mat-mdc-text-field-wrapper {
      background: unset !important;
    }
  }
}

.cdk-overlay-pane {
  min-width: fit-content;
}

.mat-mdc-form-field {
  width: 100%;
  // This padding prevents the label from being cut off.
  padding-top: 4px;

  .mat-mdc-text-field-wrapper {
    height: 36px;

    background: #fff;

    .mat-mdc-form-field-flex {
      height: 36px;

      .mat-mdc-form-field-infix {
        max-height: 36px;
        padding: 9px 0;
      }

      .mat-mdc-floating-label {
        top: 50%;

        &.mdc-floating-label--float-above {
          transform: translateY(-23px) scale(0.75);
        }
      }
    }

    .mdc-line-ripple {
      &::before {
        border-bottom: unset;
      }

      &::after {
        border-bottom: unset;
      }
    }
  }

  &:hover {
    .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }
  }

  &.mat-focused {
    .mat-mdc-form-field-focus-overlay {
      opacity: 0;
    }
  }

  &.invisible {
    text-align: right;

    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mat-mdc-select {
      .mat-mdc-select-trigger {
        .mat-mdc-select-arrow-wrapper {
          margin-left: 16px;
        }
      }
    }

    .mat-mdc-text-field-wrapper {
      padding: 0;

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-icon-suffix {
          .mat-mdc-icon-button {
            &:hover {
              .mat-mdc-button-persistent-ripple {
                &::before {
                  opacity: 0;
                }
              }
            }

            &:active {
              .mat-mdc-button-persistent-ripple {
                &::before {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  &.w50 {
    width: 50%;
  }

  /* Date range picker */
  &.rounded {
    &.mat-mdc-form-field-type-mat-date-range-input {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }

      .mat-mdc-text-field-wrapper {
        height: 32px;
      }

      .mdc-notched-outline {
        background-color: RGBA(0, 0, 0, 0.1);
        border-radius: 16px;
      }

      .mdc-text-field--outlined {
        &:not(.mdc-text-field--disabled) {
          border-top: 1px;
          border-top-style: groove;
          border-bottom: 1px;
          border-bottom-style: groove;

          .mdc-notched-outline__leading,
          .mdc-notched-outline__notch,
          .mdc-notched-outline__trailing {
            border: none;
          }
        }
      }
    }
  }
}

.mat-sort-header-arrow {
  color: RGBA(255, 255, 255, 0.87);
}

.mat-mdc-radio-button {
  .mdc-radio {
    .mdc-radio__native-control {
      &:enabled+.mdc-radio__background {
        .mdc-radio__inner-circle {
          border-color: mat.get-color-from-palette($rb-primary, 500) !important;
        }
      }

      &:enabled {
        &:checked+.mdc-radio__background {
          .mdc-radio__outer-circle {
            border-color: mat.get-color-from-palette($rb-primary, 500) !important;
          }
        }
      }
    }
  }
}

.mat-mdc-tab-group {
  &.mat-mdc-tab-group-stretch-tabs>.mat-mdc-tab-header {
    .mat-mdc-tab {
      flex-grow: unset !important;
      min-width: 160px;
    }
  }
}

.mat-mdc-tab-list {
  .mat-mdc-tab-link {
    flex-grow: unset !important;
    min-width: 160px;
  }
}

.checkbox-wrapper {
  p {
    margin-bottom: 0;
  }
}

.mat-mdc-tab-body-wrapper {
  height: 100%;
}

.mat-mdc-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin: auto;
}

.mat-mdc-raised-button {
  &.mat-primary {
    color: #fff !important;
  }
}

.mat-mdc-unelevated-button {
  &.mat-primary {
    color: #fff !important;
  }
}

.media-manager {
  .mat-mdc-tab-body-content {
    height: unset;
  }

  .mat-mdc-tab-header {
    background-color: #273943;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .mdc-tab__text-label {
    color: #d8cfe4 !important;
  }

  .mdc-tab--active {
    .mdc-tab__text-label {
      color: #fff !important;
    }
  }
}

.mat-mdc-tab-group {
  flex-grow: 1;
}

.device {
  .mat-mdc-radio-group {
    display: flex;
    margin-bottom: 24px;

    .mdc-radio {
      display: none;
    }

    .mat-mdc-radio-button {
      display: flex;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.1);
      height: 32px;
      margin-right: 10px;
      padding: 0 12px;
      border-radius: 16px;

      &.active {
        background-color: rgba(0, 0, 0, 0.15);
      }

      .mat-icon {
        height: 14px;
        width: 19px;
        font-size: 19px;
        padding-right: 5px;
        color: black;
      }
    }
  }
}

.mat-mdc-checkbox {
  .mdc-checkbox {
    .mdc-checkbox__native-control {
      &:enabled~.mdc-checkbox__background {
        .mdc-checkbox__checkmark {
          color: #fff !important;
        }
      }
    }
  }
}

.mat-mdc-chip {
  .mdc-evolution-chip__action {
    .mdc-evolution-chip__graphic {
      display: none;
    }

    .mdc-evolution-chip__text-label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 10px;
      margin-right: 5px;
      font-weight: 400;

      div {
        margin-left: 5px;
        margin-top: 2px;
      }
    }
  }

  &.mat-mdc-standard-chip {
    &.mat-accent {
      &.mat-mdc-chip-selected {
        border: 1px solid mat.get-color-from-palette($rb-primary, 500);

        .mat-icon {
          color: mat.get-color-from-palette($rb-primary, 500);
        }
      }
    }
  }


  &.status {
    height: 18px;

    &.active {
      background-color: rgb(52, 199, 89);

      .mdc-evolution-chip__text-label {
        color: #fff;
      }
    }

    &.pending {
      background-color: rgb(255, 149, 0);

      .mdc-evolution-chip__text-label {
        color: #fff;
      }
    }

    .mdc-evolution-chip__action {
      padding-left: 6px;
      padding-right: 6px;

      .mdc-evolution-chip__text-label {
        margin: 0;
        font-weight: 500;
      }
    }
  }
}

.mdc-text-field--outlined {
  &:not(.mdc-text-field--disabled) {

    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.mdc-menu-surface {
  &.mat-mdc-select-panel {
    overflow-x: hidden;
  }
}

.login-container {
  .mdc-button {
    padding: 8px 10px 8px 10px;

    .mdc-button__label {
      display: flex;
    }
  }
}

/* Mat slider toggle styling */
mat-slide-toggle {
  .mdc-switch__icons {
    display: none;
  }
}

.project-details {
  mat-datepicker-toggle {
    width: 24px;
    height: 24px;
  }

  .adjust-date {
    mat-form-field {
      .mat-mdc-form-field-infix {
        width: 100px;

        .mat-datepicker-input {
          width: 80px;
        }
      }
    }

    .mat-button-ripple {
      display: none;
    }
  }

  .adjust-input {
    mat-form-field {
      margin-top: 15px;
    }
  }

  .mat-datepicker-toggle {
    margin-right: -15px;
  }

  .mat-mdc-button-ripple,
  .mat-mdc-button-persistent-ripple {
    display: none;
  }
}