.device {

  h2,
  h3,
  p {
    font-family: "Roboto", "Open Sans", sans-serif;
    font-style: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.15px;
    line-height: 24px;
  }

  p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 20px;

    &.small-text {
      color: rgba(18, 18, 18, 0.74);
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.4px;
      line-height: 16px;
    }
  }

  &.section-title {
    font-family: "Roboto", "Open Sans", sans-serif;
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 10px;
    font-weight: 500;
    font-style: normal;
    letter-spacing: 1.5px;
    text-align: left;
    line-height: 16px;
    text-transform: uppercase;
  }

  &.information-list {
    position: relative;
    padding: 4px 0 4px 16px;
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    border-radius: 4px;

    .list-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      border-bottom: 1px solid #e6e6e6;
      height: 48px;

      &.no-height {
        height: unset;
      }

      &.padding {
        padding: 16px 16px 16px 0;
        box-sizing: border-box;
      }

      &.multi-row {
        flex-direction: column;
        height: unset;
        align-items: flex-start;

        .bold {
          padding-bottom: 0;
          margin-bottom: -5px;
        }
      }

      >span {
        margin-right: 8px;
        padding: 12px 8px 12px 0;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.74);
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0.25px;
        text-align: left;
        line-height: 20px;

        &.date {
          margin: 0;
          padding: 0;
        }

        &.no-wrap {
          white-space: nowrap;
        }

        &.error {
          color: red;
        }

        mat-icon {
          margin: 0;
          padding: 0;
          margin-left: 8px;
          display: flex;
          align-items: center;
          font-size: 20px;
          width: 18px;
          height: 18px;
          vertical-align: center;
        }

        &.navigate {
          cursor: pointer;
        }

        &:has(mat-form-field) {
          width: 100%;
          justify-content: flex-end;
        }
      }

      .bold {
        color: rgba(0, 0, 0, 1);
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 0.25px;
        text-align: left;
        line-height: 20px;
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  &.buttons {
    border-top: 1px solid rgb(230, 230, 230);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 34px;
    padding: 16px 24px;

    .next-btn,
    .save-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      border-radius: 4px;
      height: 36px;
      width: 88px;

      &:disabled {
        background-color: rgba(0, 157, 247, 0.5);
        color: #ffffff;
      }
    }

    .save-btn {
      width: 110px;
      margin-left: 16px;
      padding-left: 0px;
      padding-right: 0px;
    }

    mat-icon {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 26px;
      width: 26px;
      color: rgba(0, 0, 0, 0.6);
    }

    .back_to_previous {
      color: rgba(0, 0, 0, 0.74);
      font-family: "Roboto", "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 1.25px;
      text-align: left;

      mat-icon {
        display: flex;

      }
    }

    .blue {
      color: rgba(0, 157, 247, 1);
    }
  }
}

section {
  min-width: 30%;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;
  border-bottom: 1px solid rgb(230, 230, 230);

  &:last-child {
    border-bottom-width: 0px;
  }

  &.row {
    flex-direction: column;
  }

  .wide {
    flex: 1.5;
    flex-basis: 100%;

    * {
      flex: 1;
    }
  }

  h3,
  h4,
  h5 {
    width: 100%;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    min-width: 120px;
    display: flex;
    gap: 16px;
    flex: 1;

    li {
      display: inline-block;
      color: rgba(0, 0, 0, 0.87);
      font-size: 16px;
      flex: 1;

      h5 {
        font-size: 14px;
        letter-spacing: 0.1px;
        line-height: 24px;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}